import React from "react";
import CTA from "../cta/cta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FeaturedService = ({ title, overview, icon, link }) => {
  return (
    <div className="service">
      <div className="icon">
        <FontAwesomeIcon icon={`${icon}`}/>
      </div>
      <span className="title">{`${title}`}</span>
      <span className="overview">{`${overview}`}</span>
      <CTA text="Learn More" link={link}></CTA>
    </div>
  );
};

// FeaturedService.propTypes = {
// 	title: string.isRequired,
// 	overview: string.isRequired,
// 	icon: string.isRequired,
// 	link: string.isRequired
// };

export default FeaturedService;
