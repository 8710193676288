import React from "react";
import "./index.scss";
import Layout from "../components/layout/layout";
import FeaturedService from "../components/services/featuredService";
import Banner from "../components/banner/banner";
import CTA from "../components/cta/cta";
import { graphql, useStaticQuery } from "gatsby";

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query {
      file (relativePath: {eq: "james-owen-442400-unsplash.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const backgroundImage = data.file.childImageSharp.fluid.src;

  return (
    <Layout
      title="Online Mixing & Mastering Services"
      description="Breathe new life in your songs with our online Mixing & Mastering services. SavviProductions has everything you need to help you get a great sound."
    >
      <Banner
        title="Online Mixing & Mastering Services"
        background={backgroundImage}
        height="100vh"
        fixed="fixed"
      >
        <CTA text="Get Started" link="#featured-services"/>
      </Banner>
      <div id="featured-services">
        <div className="container">
          <FeaturedService
            title="Mixing"
            overview="Take your song to new heights with a crisp, professional sounding mix."
            icon="sliders-h"
            link="/mixing"
          />
          <FeaturedService
            title="Mastering"
            overview="Give your mix that final level of polish and commercial loudness before distribution."
            icon="volume-up"
            link="/mastering"
          />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
